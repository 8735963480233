import { Typography } from "@mui/material";
import React, { FC } from "react";
import { minimumHeight } from "../../../constants/app_constants";

export type Header1TextProps = {
  text: string;
  color?: string;
};

const Header1Text: FC<Header1TextProps> = ({ text, color }) =>
  <>
    <Typography
      variant="h1"
      component="h1"
      fontFamily='"M PLUS Rounded 1c"'
      color={color}
      sx={{
        display: {
          xs: "none",
          lg: "block"
        }
      }}
      data-aos="fade-up"
      data-aos-duration="1000"
    >
      {text}
    </Typography>
    <Typography
      variant={window.innerHeight < minimumHeight ? "h4" : "h3"}
      component="h1"
      fontFamily='"M PLUS Rounded 1c"'
      color={color}
      sx={{
        display: {
          xs: "block",
          lg: "none"
        }
      }}
      data-aos="fade-up"
      data-aos-duration="1000"
    >
      {text}
    </Typography>
  </>;

Header1Text.defaultProps = {
  color: "#ffffff"
};

export default Header1Text;