import { Box } from "@mui/material";
import React, { FC } from "react";
import { minimumHeight } from "../../../constants/app_constants";

export type AppFrameImageProps = {
  src: string;
};

const AppFrameImage: FC<AppFrameImageProps> = ({ src }) => <>
  <Box
    sx={{
      display: {
        xs: "none",
        lg: "block"
      }
    }}
  >
    <img
      src={src}
      alt="app screen shot"
      style={{
        objectFit: "contain",
        height: "75vh",
        width: "37.5vh"
      }}
    />
  </Box>
  <Box
    sx={{
      display: {
        xs: "none",
        sm: "block",
        lg: "none"
      }
    }}
  >
    <img
      src={src}
      alt="app screen shot"
      style={{
        objectFit: "contain",
        height: "60vh",
        width: "30vh"
      }}
    />
  </Box>
  <Box
    sx={{
      display: {
        xs: "block",
        sm: "none"
      }
    }}
  >
    <img
      src={src}
      alt="app screen shot"
      style={{
        objectFit: "contain",
        height: window.innerHeight < minimumHeight ? "30vh" : "35vh",
        width: window.innerHeight < minimumHeight ? "15vh" : "17.5vh"
      }}
    />
  </Box>
</>;

export default AppFrameImage;