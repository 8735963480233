import WebsitesSlideBase from "./WebsitesSlideBase";

const RyurakuSlide = () => <WebsitesSlideBase
  appName="龍楽会オフィシャルホームページ"
  macbookSrc="/img/development/websites/ryuraku/macbook_events.webp"
  iMacSrc="/img/development/websites/ryuraku/imac_reg_lives.webp"
  serviceLink="https://ryuraku-info.web.app/"
  linkLabel="SHOW 龍楽会オフィシャルホームページ"
/>;

export default RyurakuSlide;