import React, { FC, ReactNode } from "react";
import {
  Box,
  Button,
  Dialog, DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Stack,
  Typography
} from "@mui/material";
import Header1Text from "../common/texts/Header1Text";
import StoreLinkButtons from "../common/buttons/StoreLinkButtons";
import AppFrameImage from "../common/images/AppFrameImage";
import { minimumHeight } from "../../constants/app_constants";

export type ContentBaseProps = {
  appName: string;
  contentId: string;
  bgColor: string;
  bgImageSrc?: string;
  appIconSrc: string;
  appNameColor: string;
  description: string;
  descriptionColor: string;
  playStoreLink: string;
  appStoreLink: string;
  mainScreenShotSrc: string;
  privacyPolicy: ReactNode;
  privacyPolicyColor: string;
};

const ContentBase: FC<ContentBaseProps> = ({
  appName,
  contentId,
  bgColor,
  bgImageSrc,
  appIconSrc,
  appNameColor,
  description,
  descriptionColor,
  playStoreLink,
  appStoreLink,
  mainScreenShotSrc,
  privacyPolicy,
  privacyPolicyColor
}) => {
  const [ open, setOpen ] = React.useState(false);
  
  const handleClickOpen = () => {
    setOpen(true);
  };
  
  const handleClose = () => {
    setOpen(false);
  };
  
  return <>
    <Grid
      id={contentId}
      container
      width="100%"
      minHeight={window.innerHeight}
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      bgcolor={bgImageSrc ? "#ffffff" : bgColor}
      sx={bgImageSrc ? {
        backgroundImage: bgImageSrc,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      } : undefined}
    >
      <Grid item xs={12} sx={{
        display: {
          xs: "none",
          sm: "block"
        }
      }}>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Stack
            direction="column"
          >
            <div style={{ textAlign: "center" }}>
              <img src={appIconSrc} alt={`${appName} icon`} height="100px" width="100px" />
            </div>
            
            <Header1Text text={appName} color={appNameColor} />
            
            <Typography
              variant="h6"
              component="h3"
              color={descriptionColor}
              fontWeight="500"
              style={{ marginTop: "8px" }}
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="100"
            >
              {description}
            </Typography>
            
            <StoreLinkButtons
              playStoreLink={playStoreLink}
              appStoreLink={appStoreLink}
            />
            
            <Button
              variant="text"
              onClick={handleClickOpen}
              style={{
                marginTop: "32px",
                color: privacyPolicyColor
              }}
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="300"
            >
              プライバシーポリシー
            </Button>
          </Stack>
          
          <Box sx={{
            marginRight: "48px"
          }} />
          
          <AppFrameImage src={mainScreenShotSrc} />
        </Stack>
      </Grid>
      
      <Grid item xs={12} sx={{
        display: {
          xs: "block",
          sm: "none"
        }
      }}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Header1Text text={appName} color={appNameColor} />
          
          <Typography
            variant="h6"
            component="h3"
            fontFamily='"M PLUS Rounded 1c"'
            color={descriptionColor}
            sx={{
              display: {
                xs: "none",
                sm: "block"
              }
            }}
            style={{ marginTop: "8px" }}
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            {description}
          </Typography>
          <Typography
            variant={window.innerHeight < minimumHeight ? "caption" : "subtitle1"}
            component="h3"
            fontFamily='"M PLUS Rounded 1c"'
            color={descriptionColor}
            sx={{
              display: {
                xs: "block",
                sm: "none"
              }
            }}
            style={{ marginTop: "8px" }}
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            {description}
          </Typography>
          
          <StoreLinkButtons
            playStoreLink={playStoreLink}
            appStoreLink={appStoreLink}
          />
          
          <Button
            variant="text"
            onClick={handleClickOpen}
            style={{
              marginTop: "16px",
              marginBottom: "24px"
            }}>
            プライバシーポリシー
          </Button>
          
          <Stack direction="row" alignItems="center" spacing={4}>
            <div style={{ textAlign: "center" }}>
              <img
                src={appIconSrc}
                alt={`${appName} icon`}
                height={window.innerHeight < minimumHeight ? "64px" : "100px"}
                width={window.innerHeight < minimumHeight ? "64px" : "100px"}
              />
            </div>
            <AppFrameImage src={mainScreenShotSrc} />
          </Stack>
        </Stack>
      </Grid>
    </Grid>
    
    <Dialog
      open={open}
      onClose={handleClose}
      aria-describedby={`${appName} Privacy policy`}
      maxWidth="md"
    >
      <DialogTitle>{appName} プライバシーポリシー</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {privacyPolicy}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleClose}>閉じる</Button>
      </DialogActions>
    </Dialog>
  </>;
};

ContentBase.defaultProps = {
  bgImageSrc: undefined
};

export default ContentBase;