import AppsSlideBase from "./AppsSlideBase";

const FlashMentalArithmetricSlide = () => <AppsSlideBase
  appName="フラッシュ暗算アプリ"
  appIconSrc="/img/development/apps/flash_mental_arithmetric/app_icon.png"
  iPhone13Src1="/img/development/apps/flash_mental_arithmetric/iPhone13_top.webp"
  iPhone13Src2="/img/development/apps/flash_mental_arithmetric/iPhone13_result.webp"
  pixel6Src1="/img/development/apps/flash_mental_arithmetric/pixel6_top.webp"
  pixel6Src2="/img/development/apps/flash_mental_arithmetric/pixel6_result.webp"
  playStoreLink="-"
  appStoreLink="-"
/>;

export default FlashMentalArithmetricSlide;