import {
  AppBar, Avatar,
  Button,
  Divider,
  Drawer, IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText, Toolbar
} from "@mui/material";
import React, { useState } from "react";
import { scroller, animateScroll } from "react-scroll";
import MenuIcon from "@mui/icons-material/Menu";

const Sidebar = () => {
  const [ mobileOpen, setMobileOpen ] = useState(false);
  
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  
  const drawerMenu = <div>
    <Button variant="text" onClick={() => {
      animateScroll.scrollToTop({
        duration: 300
      });
      setMobileOpen(false);
    }}>
      <img
        src="/img/logo/logo_light.png"
        alt="BPM Japan LLC"
        style={{ width: "100%", padding: "24px 16px 24px 16px" }}
      />
    </Button>
    <Divider />
    <List>
      {/* Home */}
      <ListItemButton
        onClick={() => {
          scroller.scrollTo("home", {
            duration: 300,
            smooth: true
          });
          setMobileOpen(false);
        }}
        data-to-scrollspy-id="home"
        sx={{ borderRadius: "24px", margin: "24px 8px 16px 8px" }}
      >
        <ListItemIcon>
          <img src="/img/icons/home.svg" alt="Home" height="24px" width="auto" />
        </ListItemIcon>
        <ListItemText primary="Home" />
      </ListItemButton>
      
      {/* Our Service */}
      <ListItemButton
        onClick={() => {
          scroller.scrollTo("our-services", {
            duration: 300,
            smooth: true
          });
          setMobileOpen(false);
        }}
        data-to-scrollspy-id="our-services"
        sx={{ borderRadius: "24px", margin: "16px 8px 8px 8px" }}
      >
        <ListItemIcon>
          <img src="/img/icons/idea.svg" alt="Our services" height="24px" width="auto" />
        </ListItemIcon>
        <ListItemText primary="Our Services" />
      </ListItemButton>
      
      {/* Sub menu - Our Service */}
      <List component="div" disablePadding>
        {/* Mystery Box */}
        <ListItemButton
          onClick={() => {
            scroller.scrollTo("mystery-box", {
              duration: 300,
              smooth: true
            });
            setMobileOpen(false);
          }}
          data-to-scrollspy-id="mystery-box"
          sx={{ borderRadius: "24px", margin: "4px 8px 4px 8px", pl: 4 }}
        >
          <ListItemIcon>
            <Avatar src="img/mystery-box/app_icon.webp" />
          </ListItemIcon>
          <ListItemText primary="Mystery Box" />
        </ListItemButton>
        
        {/* Mission TODO */}
        <ListItemButton
          onClick={() => {
            scroller.scrollTo("mission-todo", {
              duration: 300,
              smooth: true
            });
            setMobileOpen(false);
          }}
          data-to-scrollspy-id="mission-todo"
          sx={{ borderRadius: "24px", margin: "4px 8px 4px 8px", pl: 4 }}
        >
          <ListItemIcon>
            <Avatar src="img/mission-todo/app_icon.webp" />
          </ListItemIcon>
          <ListItemText primary="Mission TODO" />
        </ListItemButton>
        
        {/* 百ますたー */}
        <ListItemButton
          onClick={() => {
            scroller.scrollTo("hundred-master", {
              duration: 300,
              smooth: true
            });
            setMobileOpen(false);
          }}
          data-to-scrollspy-id="hundred-master"
          sx={{ borderRadius: "24px", margin: "4px 8px 4px 8px", pl: 4 }}
        >
          <ListItemIcon>
            <Avatar src="img/hundred-master/app_icon.png" />
          </ListItemIcon>
          <ListItemText primary="百ますたー" />
        </ListItemButton>
        
        {/* サイレンコレクション */}
        <ListItemButton
          onClick={() => {
            scroller.scrollTo("siren-collection", {
              duration: 300,
              smooth: true
            });
            setMobileOpen(false);
          }}
          data-to-scrollspy-id="siren-collection"
          sx={{ borderRadius: "24px", margin: "4px 8px 4px 8px", pl: 4 }}
        >
          <ListItemIcon>
            <Avatar src="img/siren-collection/appicon.png" />
          </ListItemIcon>
          <ListItemText primary="サイレンコレクション" />
        </ListItemButton>
      </List>
      {/* Finish Sub menu */}
      
      {/* Development */}
      <ListItemButton
        onClick={() => {
          scroller.scrollTo("development", {
            duration: 300,
            smooth: true
          });
          setMobileOpen(false);
        }}
        data-to-scrollspy-id="development"
        sx={{ borderRadius: "24px", margin: "16px 8px 8px 8px" }}
      >
        <ListItemIcon>
          <img src="/img/icons/code.svg" alt="Development" height="24px" width="auto" />
        </ListItemIcon>
        <ListItemText primary="Development" />
      </ListItemButton>
      
      {/* Sub menu - Development */}
      <List component="div" disablePadding>
        
        {/* Apps */}
        <ListItemButton
          onClick={() => {
            scroller.scrollTo("apps", {
              duration: 300,
              smooth: true
            });
            setMobileOpen(false);
          }}
          data-to-scrollspy-id="apps"
          sx={{ borderRadius: "24px", margin: "4px 8px 4px 8px", pl: 4 }}
        >
          <ListItemIcon>
            <img src="/img/icons/apps.svg" alt="Apps" height="24px" width="auto" />
          </ListItemIcon>
          <ListItemText primary="Apps" />
        </ListItemButton>
        
        {/* Web sites */}
        <ListItemButton
          onClick={() => {
            scroller.scrollTo("web-sites", {
              duration: 300,
              smooth: true
            });
            setMobileOpen(false);
          }}
          data-to-scrollspy-id="web-sites"
          sx={{ borderRadius: "24px", margin: "4px 8px 4px 8px", pl: 4 }}
        >
          <ListItemIcon>
            <img src="/img/icons/web.svg" alt="Web Sites" height="24px" width="auto" />
          </ListItemIcon>
          <ListItemText primary="Web Sites" />
        </ListItemButton>
        
        {/* Others */}
        <ListItemButton
          onClick={() => {
            scroller.scrollTo("others", {
              duration: 300,
              smooth: true
            });
            setMobileOpen(false);
          }}
          data-to-scrollspy-id="others"
          sx={{ borderRadius: "24px", margin: "4px 8px 4px 8px", pl: 4 }}
        >
          <ListItemIcon>
            <img src="/img/icons/others.svg" alt="Others" height="24px" width="auto" />
          </ListItemIcon>
          <ListItemText primary="Others" />
        </ListItemButton>
      
      </List>
    
    </List>
    <div style={{ marginTop: "24px" }} />
  </div>;
  
  return <>
    <AppBar
      position="fixed"
      sx={{
        display: { xs: "block", md: "none" }
      }}
      style={{ background: "transparent", boxShadow: "none" }}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
        >
          <MenuIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
    <Drawer
      variant="temporary"
      open={mobileOpen}
      onClose={() => setMobileOpen(false)}
      sx={{
        display: { xs: "block", md: "none" },
        width: "240px",
        [`& .MuiDrawer-paper`]: { width: "240px", boxSizing: "border-box" }
      }}
    >
      {drawerMenu}
    </Drawer>
    
    <Drawer
      variant="permanent"
      open
      sx={{
        display: { xs: "none", md: "block" },
        width: "320px",
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: "320px", boxSizing: "border-box" }
      }}
    >
      {drawerMenu}
    </Drawer>
  </>;
};

export default Sidebar;