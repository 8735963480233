import AppsSlideBase from "./AppsSlideBase";

const DressWorkSlide = () => <AppsSlideBase
  appIconSrc="/img/development/apps/dress-work/app_icon.webp"
  iPhone13Src1="/img/development/apps/dress-work/iPhone13_signin.webp"
  iPhone13Src2="/img/development/apps/dress-work/iPhone13_main.webp"
  appName="Dress Work"
  pixel6Src1="/img/development/apps/dress-work/pixel6_signin.webp"
  pixel6Src2="/img/development/apps/dress-work/pixel6_main.webp"
  playStoreLink="https://play.google.com/store/apps/details?id=tokyo.okkun.dresswork"
  appStoreLink="https://apps.apple.com/jp/app/id1627904202"
/>;

export default DressWorkSlide;