import { createTheme } from "@mui/material";

export default createTheme({
  palette: {
    background: {
      default: "#EFF5F8"
    },
    primary: {
      main: "#CBCFC7"
    },
    secondary: {
      main: "#FFFFFF"
    }
  },
  typography: {
    allVariants: {
      color: "#362f5f"
    },
    fontFamily: [
      "Poppins",
      "Kiwi Maru"
    ].join(",")
  }
});
