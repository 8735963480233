import { Container, Grid, Theme, Typography, useMediaQuery } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import { minimumHeight } from "../../../constants/app_constants";
import PandaAppsSlide from "./PandaAppsSlide";
import ImagesRouletteSlide from "./ImagesRouletteSlide";
import DressWorkSlide from "./DressWorkSlide";
import FirstImpressionGameSlide from "./FirstImpressionGameSlide";
import FlashMentalArithmetricSlide from "./FlashMentalArithmetricSlide";
import PscsForBJSlide from "./PscsForBJSlide";
import NoPublishAppSlide from "./NoPublishAppSlide";

const Apps = () => {
  const isMobileSize = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  return <Grid
    id="apps"
    container
    width="100%"
    minHeight={window.innerHeight}
    alignItems="center"
    justifyContent="center"
    textAlign="center"
    bgcolor="#f5f8fc">
    <Grid
      item xs={12}
    >
      <Typography
        sx={{ typography: { md: "h2", xs: "h4" } }}
        component="h1"
        data-aos="fade-up"
        data-aos-duration="1200"
      >
        Apps
      </Typography>
      
      <Typography
        variant={window.innerHeight < minimumHeight ? "caption" : "body1"}
        component="h3"
        color="#A9A9A9"
        fontWeight="500"
        mt="8px"
        mx={2}
        data-aos="fade-up"
        data-aos-duration="1200"
      >
        Android, iOS等のスマートフォン向けアプリケーションの開発実績です。<br />
        公開許可を頂いた実績のみ掲載しています。<br />
        {isMobileSize ? null : "Flutterを用いたクロスプラットフォーム開発をメインとし、Android, iOS ネイティブ開発も行っています。"}
      </Typography>
      
      <div style={{ marginTop: window.innerHeight < minimumHeight ? "16px" : "32px" }} />
      
      <Container
        data-aos="fade-up"
        data-aos-duration="1500"
        data-aos-delay="300"
      >
        <Carousel
          navButtonsAlwaysVisible
          animation="slide"
          duration={500}
          autoPlay={false}
          cycleNavigation={false}
        >
          <PandaAppsSlide />
          <FlashMentalArithmetricSlide />
          <ImagesRouletteSlide />
          <DressWorkSlide />
          <FirstImpressionGameSlide />
          <PscsForBJSlide />
          <NoPublishAppSlide />
        </Carousel>
      </Container>
    </Grid>
  </Grid>;
};

export default Apps;