import SlideBase from "./SlideBase";

const HundredMasterSlide = () =>
  <SlideBase
    appName="百ますたー"
    appIconSrc="img/hundred-master/app_icon.png"
    pixel6Src1="img/hundred-master/pixel6_splash.webp"
    pixel6Src2="img/hundred-master/pixel6_main.webp"
    iPhone13Src1="img/hundred-master/iPhone13_splash.webp"
    iPhone13Src2="img/hundred-master/iPhone13_main.webp"
    scrollTo="hundred-master"
  />;

export default HundredMasterSlide;