import AppsSlideBase from "./AppsSlideBase";

const PandaAppsSlide = () => <AppsSlideBase
  appName="愚痴や呪いの掲示板PANDA"
  appIconSrc="img/development/apps/panda/app_icon.webp"
  pixel6Src1="img/development/apps/panda/iPhone1.webp"
  pixel6Src2="img/development/apps/panda/iPhone2.webp"
  iPhone13Src1="img/development/apps/panda/iPhone3.webp"
  iPhone13Src2="img/development/apps/panda/iPhone4.webp"
  playStoreLink=""
  appStoreLink="https://apps.apple.com/jp/app/id1593836917"
/>;

export default PandaAppsSlide;