import AppsSlideBase from "./AppsSlideBase";

const ImagesRouletteSlide = () => <AppsSlideBase
  appName="Images Roulette(仮称)"
  appIconSrc="img/development/apps/images-roulette/app_icon.png"
  pixel6Src1="img/development/apps/images-roulette/pixel6_main.webp"
  pixel6Src2="img/development/apps/images-roulette/pixel6_answer.webp"
  iPhone13Src1="img/development/apps/images-roulette/pixel6_main.webp"
  iPhone13Src2="img/development/apps/images-roulette/pixel6_answer.webp"
  playStoreLink=""
  appStoreLink=""
  isHorizontalImages
/>;

export default ImagesRouletteSlide;