import { Button, Card, CardContent, Stack, Theme, Typography, useMediaQuery } from "@mui/material";
import React, { FC, ReactNode, useEffect, useState } from "react";
import { scroller } from "react-scroll";

export type CategoryCardProps = {
  iconSrc: string;
  title: ReactNode;
  shortTitle: ReactNode;
  scrollTo: string;
};

const CategoryCard: FC<CategoryCardProps> = ({ iconSrc, title, shortTitle, scrollTo }) => {
  const [ state, setState ] = useState(window.innerWidth < 1300 ? shortTitle : title);
  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setState(newWidth < 1300 ? shortTitle : title);
    };
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, [ shortTitle, title ]);
  
  const isMobileSize = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  
  return <Card
    elevation={0}
    style={{ borderRadius: 32, backgroundColor: "#f5f8fc" }}
    sx={{
      mx: {
        xs: 0,
        xl: 2
      }
    }}
  >
    <CardContent
      sx={{
        pt: isMobileSize ? 3 : 4,
        px: {
          xs: 4,
          xl: 8
        }
      }}
      style={{
        paddingBottom: 10
      }}
    >
      <Stack
        direction="column"
        spacing={isMobileSize ? 1 : 3}
        justifyContent="start"
      >
        <img
          src={iconSrc}
          alt="Apps"
          height={isMobileSize ? "32px" : "48px"}
          width={isMobileSize ? "32px" : "48px"}
        />
        <Typography
          variant="h5"
          component="h3"
          textAlign="start"
          fontWeight="600"
          fontFamily="Poppins"
          color="#1a1a1a"
          lineHeight={1.5}
        >
          {state}
        </Typography>
        <Button
          variant="text"
          style={{ color: "#7e7e7e" }}
          onClick={() => {
            scroller.scrollTo(scrollTo, {
              duration: 300,
              smooth: true
            });
          }}
        >
          Show more
        </Button>
      </Stack>
    </CardContent>
  </Card>;
};

export default CategoryCard;