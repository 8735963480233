import AppsSlideBase from "./AppsSlideBase";

const FirstImpressionGameSlide = () => <AppsSlideBase
  appName="みんなで盛り上がる！第一印象ゲーム"
  appIconSrc="/img/development/apps/first-impression-game/app_icon.png"
  iPhone13Src1="/img/development/apps/first-impression-game/iPhone13_top.webp"
  iPhone13Src2="/img/development/apps/first-impression-game/iPhone13_vote.webp"
  pixel6Src1="/img/development/apps/first-impression-game/pixel6_top.webp"
  pixel6Src2="/img/development/apps/first-impression-game/pixel6_vote.webp"
  appStoreLink=""
  playStoreLink=""
/>;

export default FirstImpressionGameSlide;