import { Container, Grid, Theme, Typography, useMediaQuery } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import React from "react";
import { minimumHeight } from "../../../constants/app_constants";
import SunbowlSlide from "./SunbowlSlide";
import RyurakuSlide from "./RyurakuSlide";
import NoPublishWebSlide from "./NoPublishWebSlide";

const WebSites = () => {
  const isMobileSize = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  return <Grid
    id="web-sites"
    container
    width="100%"
    minHeight={window.innerHeight}
    alignItems="center"
    justifyContent="center"
    textAlign="center"
    bgcolor="#ffffff">
    <Grid
      item xs={12}
    >
      <Typography
        sx={{ typography: { md: "h2", xs: "h4" } }}
        component="h1"
        data-aos="fade-up"
        data-aos-duration="1200"
      >
        Web Sites
      </Typography>
      
      <Typography
        variant={window.innerHeight < minimumHeight ? "caption" : "body1"}
        component="h3"
        color="#A9A9A9"
        fontWeight="500"
        mt="8px"
        mx={2}
        data-aos="fade-up"
        data-aos-duration="1200"
      >
        Webサイトや、Webアプリケーションの開発実績です。<br />
        公開許可を頂いた実績のみ掲載しています。<br />
        {isMobileSize ? null : "React, Vue.jsフレームワークによる開発実績が多くなっています。"}
      </Typography>
      
      <div style={{ marginTop: window.innerHeight < minimumHeight ? "16px" : "32px" }} />
      
      <Container
        data-aos="fade-up"
        data-aos-duration="1500"
        data-aos-delay="300"
      >
        <Carousel
          navButtonsAlwaysVisible
          animation="slide"
          duration={500}
          autoPlay={false}
          cycleNavigation={false}
        >
          <SunbowlSlide />
          <RyurakuSlide />
          <NoPublishWebSlide />
        </Carousel>
      </Container>
    </Grid>
  </Grid>;
};

export default WebSites;