import React, { useEffect } from "react";
import AOS from "aos";
import "./App.css";
import "aos/dist/aos.css";
import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import ScrollSpy from "react-ui-scrollspy";
import { Toaster } from "react-hot-toast";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Sidebar from "./components/sidebar/Sidebar";
import themes from "./themes";
import Home from "./components/home/Home";
import OurServices from "./components/our-services/OurServices";
import MissionTodoContent from "./components/our-services/mission-todo/MissionTodoContent";
import MysteryBoxContent from "./components/our-services/mystery-box/MysteryBoxContent";
import Development from "./components/development/Development";
import HundredMasterContent from "./components/our-services/hundred-master/HundredMasterContent";
import Apps from "./components/development/apps/Apps";
import WebSites from "./components/development/websites/WebSites";
import Others from "./components/development/others/Others";
import HundredMasterPrivacyPolicy from "./privacy/HundredMasterPrivacyPolicy";
import MissionTodoPrivacyPolicy from "./privacy/MissionTodoPrivacyPolicy";
import MysteryBoxPrivacyPolicy from "./privacy/MysteryBoxPrivacyPolicy";
import SirenCollectionContent from "./components/our-services/siren-collection/SirenCollectionContent";
import SirenCollectionPrivacyPolicy from "./privacy/SirenCollectionPrivacyPolicy";

const App = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  
  return <div className="App">
    <ThemeProvider theme={themes}>
      <CssBaseline>
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={
                <Box sx={{ display: "flex" }}>
                  <Sidebar />
                  <Box component="main" sx={{ flexGrow: 1 }}>
                    <div><Toaster position="bottom-center" /></div>
                    <ScrollSpy useBoxMethod={false} scrollThrottle={100}>
                      <Home />
                      <OurServices />
                      <MysteryBoxContent />
                      <MissionTodoContent />
                      <HundredMasterContent />
                      <SirenCollectionContent />
                      <Development />
                      <Apps />
                      <WebSites />
                      <Others />
                    </ScrollSpy>
                  </Box>
                </Box>
              }
            />
            <Route
              path="/privacy/hundred-master"
              element={
                <HundredMasterPrivacyPolicy />
              }
            />
            <Route
              path="/privacy/mission-todo"
              element={
                <MissionTodoPrivacyPolicy />
              }
            />
            <Route
              path="/privacy/mystery-box"
              element={
                <MysteryBoxPrivacyPolicy />
              }
            />
            <Route
              path="/privacy/siren-collection"
              element={
                <SirenCollectionPrivacyPolicy />
              }
            />
          </Routes>
        </BrowserRouter>
      </CssBaseline>
    </ThemeProvider>
  </div>;
};

export default App;
