import SlideBase from "./SlideBase";

const MissionTodoSlide = () =>
  <SlideBase
    appName="Mission TODO"
    appIconSrc="img/mission-todo/app_icon.webp"
    pixel6Src1="img/mission-todo/pixel6_missions.webp"
    pixel6Src2="img/mission-todo/pixel6_shop.webp"
    iPhone13Src1="img/mission-todo/iPhone13_missions.webp"
    iPhone13Src2="img/mission-todo/iPhone13_shop.webp"
    scrollTo="mission-todo"
  />;


export default MissionTodoSlide;