import { Stack, Typography } from "@mui/material";
import { minimumHeight } from "../../../constants/app_constants";

const NoPublishWebSlide = () => <Stack
  direction="column"
  alignItems="center"
>
  <Stack
    direction="row"
    alignItems="center"
    justifyContent="center"
    spacing={2}
    sx={{
      display: {
        xs: "flex",
        md: "none"
      }
    }}
  >
    <Typography
      variant="h6"
      component="h2">
      非公開実績概要
    </Typography>
  </Stack>
  <Stack
    direction="row"
    alignItems="center"
    justifyContent="center"
    spacing={2}
    sx={{
      pt: 2,
      display: {
        xs: "none",
        md: "flex"
      }
    }}
  >
    <Typography
      variant="h4"
      component="h2">
      非公開実績概要
    </Typography>
  </Stack>
  
  <div style={{ marginTop: window.innerHeight < minimumHeight ? "8px" : "16px" }} />
  
  <Stack
    direction="column"
    justifyContent="center"
    spacing={2}
    sx={{ py: 2 }}
  >
    <Typography
      variant={window.innerHeight < minimumHeight ? "caption" : "body1"}
      component="h3"
      color="#A9A9A9"
      fontWeight="500"
      mt="8px"
      mx={2}
    >
      某大手医療系システム開発メーカー&nbsp;様<br />
      診察予約システム
    </Typography>
    <Typography
      variant={window.innerHeight < minimumHeight ? "caption" : "body1"}
      component="h3"
      color="#A9A9A9"
      fontWeight="500"
      mt="8px"
      mx={2}
    >
      某農業協同組合&nbsp;様<br />
      畜産管理システム開発
    </Typography>
    <Typography
      variant={window.innerHeight < minimumHeight ? "caption" : "body1"}
      component="h3"
      color="#A9A9A9"
      fontWeight="500"
      mt="8px"
      mx={2}
    >
      And more...
    </Typography>
  </Stack>
</Stack>;

export default NoPublishWebSlide;
