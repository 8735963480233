import { FC } from "react";
import { Avatar, Box, Stack, Typography } from "@mui/material";
import StoreLinkButtons from "../../common/buttons/StoreLinkButtons";
import { minimumHeight } from "../../../constants/app_constants";

export type AppsSlideBaseProps = {
  appName: string;
  appIconSrc: string;
  pixel6Src1: string;
  pixel6Src2: string;
  iPhone13Src1: string;
  iPhone13Src2: string;
  playStoreLink: string;
  appStoreLink: string;
  isHorizontalImages?: boolean;
};

const AppsSlideBase: FC<AppsSlideBaseProps> = ({
  appName,
  appIconSrc,
  pixel6Src1,
  pixel6Src2,
  iPhone13Src1,
  iPhone13Src2,
  playStoreLink,
  appStoreLink,
  isHorizontalImages
}) =>
  <Stack
    direction="column"
    alignItems="center"
  >
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      spacing={2}
      sx={{
        display: {
          xs: "flex",
          md: "none"
        }
      }}
    >
      <Avatar src={appIconSrc} alt="app icon" />
      <Typography
        variant="h6"
        component="h2">
        {appName}
      </Typography>
    </Stack>
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      spacing={2}
      sx={{
        pt: 2,
        display: {
          xs: "none",
          md: "flex"
        }
      }}
    >
      <Avatar src={appIconSrc} alt="app icon" sx={{ width: 48, height: 48 }} />
      <Typography
        variant="h4"
        component="h2">
        {appName}
      </Typography>
    </Stack>
    
    <div style={{ marginTop: window.innerHeight < minimumHeight ? "8px" : "16px" }} />
    
    {
      !isHorizontalImages ?
        <Stack
          direction="row"
          justifyContent="center"
          spacing={2}
          sx={{ py: 2 }}
        >
          <img
            src={pixel6Src1}
            alt="Apps ScreenShot"
            height={window.innerHeight < minimumHeight ? "200" : "280"}
            width="auto"
          />
          <Box sx={{
            display: {
              xs: "none",
              lg: "block"
            }
          }}>
            <img
              src={pixel6Src2}
              alt="Apps ScreenShot"
              height={window.innerHeight < minimumHeight ? "200" : "280"}
              width="auto"
            />
          </Box>
          <Box sx={{
            display: {
              xs: "none",
              sm: "block"
            }
          }}>
            <div style={{ width: "16px" }} />
          </Box>
          <Box sx={{
            display: {
              xs: "none",
              lg: "block"
            }
          }}>
            <img
              src={iPhone13Src1}
              alt="Apps ScreenShot"
              height={window.innerHeight < minimumHeight ? "200" : "280"}
              width="auto"
            />
          </Box>
          <img
            src={iPhone13Src2}
            alt="Apps ScreenShot"
            height={window.innerHeight < minimumHeight ? "200" : "280"}
            width="auto"
          />
        </Stack>
        :
        <Stack
          direction="row"
          justifyContent="center"
          spacing={2}
          sx={{ py: 2 }}
        >
          <Stack
            direction="row-reverse"
            style={{
              transform: "rotate(-90deg)"
            }}
            sx={{
              display: {
                xs: "none",
                lg: "block"
              }
            }}
          >
            <img
              src={pixel6Src2}
              alt="Apps ScreenShot"
              height={window.innerHeight < minimumHeight ? "200" : "280"}
              width="auto"
            />
            <img
              src={pixel6Src1}
              alt="Apps ScreenShot"
              height={window.innerHeight < minimumHeight ? "200" : "280"}
              width="auto"
            />
          </Stack>
          <Box sx={{
            display: {
              xs: "none",
              lg: "block"
            }
          }}>
            <div style={{ width: "16px" }} />
          </Box>
          <Stack
            direction="row-reverse"
            style={{
              transform: "rotate(-90deg)"
            }}>
            <img
              src={iPhone13Src1}
              alt="Apps ScreenShot"
              height={window.innerHeight < minimumHeight ? "200" : "280"}
              width="auto"
            />
            <img
              src={iPhone13Src2}
              alt="Apps ScreenShot"
              height={window.innerHeight < minimumHeight ? "200" : "280"}
              width="auto"
            />
          </Stack>
        </Stack>
    }
    
    <StoreLinkButtons playStoreLink={playStoreLink} appStoreLink={appStoreLink} />
  </Stack>;

AppsSlideBase.defaultProps = {
  isHorizontalImages: false
};

export default AppsSlideBase;