import AppsSlideBase from "./AppsSlideBase";

const PscsForBJSlide = () => <AppsSlideBase
  appName="PSCS for Blackjack"
  appIconSrc="/img/development/apps/pscs-for-blackjack/app_icon.png"
  iPhone13Src1="/img/development/apps/pscs-for-blackjack/iPhone13_top.webp"
  iPhone13Src2="/img/development/apps/pscs-for-blackjack/iPhone13_config.webp"
  pixel6Src1="/img/development/apps/pscs-for-blackjack/pixel6_top.webp"
  pixel6Src2="/img/development/apps/pscs-for-blackjack/pixel6_config.webp"
  playStoreLink="https://play.google.com/store/apps/details?id=jp.pscs.pscs_blackjack"
  appStoreLink="https://apps.apple.com/jp/app/id1618575587"
/>;

export default PscsForBJSlide;