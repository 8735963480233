import { Box, Button, Stack } from "@mui/material";
import React, { FC } from "react";
import { toast } from "react-hot-toast";
import { minimumHeight } from "../../../constants/app_constants";

export type StoreLinkButtonsProps = {
  playStoreLink: string;
  appStoreLink: string;
};

const StoreLinkButtons: FC<StoreLinkButtonsProps> = ({ playStoreLink, appStoreLink }) => {
  const duration = 1500;
  return <Box
    data-aos="fade-up"
    data-aos-duration="1200"
    data-aos-delay="300"
    data-aos-offset="-150"
  >
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={2}
      sx={{
        display: {
          xs: "none",
          lg: "block"
        }
      }}
      style={{ marginTop: "24px" }}
    >
      <Button
        variant="text"
        onClick={
          () => {
            if (playStoreLink === "") {
              toast("Android リリース準備中です。", {
                duration
              });
              return;
            }
            if (playStoreLink === "-") {
              toast("非公開アプリです。", {
                duration
              });
              return;
            }
            window.open(playStoreLink);
          }
        }
      >
        <img
          src="/img/common/google_play.webp"
          alt="Google Play Link"
          style={{ width: "100%", height: "60px" }}
        />
      </Button>
      <Button variant="text"
              onClick={
                () => {
                  if (appStoreLink === "") {
                    toast("iOS リリース準備中です。", {
                      duration
                    });
                    return;
                  }
                  if (appStoreLink === "-") {
                    toast("非公開アプリです。", {
                      duration
                    });
                    return;
                  }
                  window.open(appStoreLink);
                }
              }
      >
        <img
          src="/img/common/applestore.svg"
          alt="AppStore Link"
          style={{ width: "100%", height: "64px" }}
        />
      </Button>
    </Stack>
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={window.innerHeight < minimumHeight ? 1 : 2}
      sx={{
        display: {
          xs: "block",
          lg: "none"
        },
        marginTop: {
          xs: window.innerHeight < minimumHeight ? "16px" : "24px",
          sm: "48px"
        }
      }}
    >
      <Button
        variant="text"
        onClick={
          () => {
            if (playStoreLink === "") {
              toast("Android リリース準備中です。", {
                duration
              });
              return;
            }
            if (playStoreLink === "-") {
              toast("非公開アプリです。", {
                duration
              });
              return;
            }
            window.open(playStoreLink);
          }
        }
        style={{
          width: "100%"
        }}
      >
        <img
          src="/img/common/google_play.webp"
          alt="Google Play Link"
          style={{ objectFit: "contain" }}
          height={window.innerHeight < minimumHeight ? "40px" : "56px"}
        />
      </Button>
      <Button
        variant="text"
        onClick={
          () => {
            if (appStoreLink === "") {
              toast("iOS リリース準備中です。", {
                duration
              });
              return;
            }
            if (appStoreLink === "-") {
              toast("非公開アプリです。", {
                duration
              });
              return;
            }
            window.open(appStoreLink);
          }
        }
        style={{
          width: "100%"
        }}
      >
        <img
          src="/img/common/applestore.svg"
          alt="AppStore Link"
          style={{ objectFit: "contain" }}
          height={window.innerHeight < minimumHeight ? "44px" : "auto"}
        />
      </Button>
    </Stack>
  </Box>;
};

export default StoreLinkButtons;