import { Box, Button, Grid, Typography } from "@mui/material";
import ReactTyped from "react-typed";
import { scroller } from "react-scroll";
import { minimumHeight, scrollSpeed } from "../../constants/app_constants";

const Home = () =>
  <Box id="home" minHeight={window.innerHeight}>
    <Grid
      container
      width="100%"
      minHeight={window.innerHeight}
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      bgcolor="#f5f8fc"
    >
      <Grid item xs={12}>
        <Box
          data-aos="fade-up"
          data-aos-duration="1200"
          component="img"
          src="img/logo/logo_symbol.svg"
          alt="BPM Japan LLC"
          py={2}
          sx={{
            width: {
              xs: window.innerHeight < minimumHeight ? "160px" : "185px",
              md: "225px",
              lg: "265px"
            },
            height: {
              xs: window.innerHeight < minimumHeight ? "160px" : "185px",
              md: "225px",
              lg: "265px"
            },
            objectFit: "cover"
          }} />
        
        {window.innerHeight < minimumHeight ?
          <Typography
            component="h1"
            sx={{ typography: { md: "h4", xs: "h5" } }}
            fontFamily="'Baloo Tamma 2'"
            fontWeight="600"
            color="#89b2c9ff"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay="100"
          >
            BPM Japan LLC
          </Typography> :
          <Typography
            component="h1"
            sx={{ typography: { md: "h3", xs: "h4" } }}
            fontFamily="'Baloo Tamma 2'"
            fontWeight="600"
            color="#89b2c9ff"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay="100"
          >
            BPM Japan LLC
          </Typography>
        }
        
        <div style={{ marginTop: window.innerHeight < minimumHeight ? "8px" : "24px" }} />
        
        <Typography
          component="h2"
          sx={{ typography: { md: "h2", xs: window.innerHeight < minimumHeight ? "h4" : "h3" } }}
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay="200"
        >
          <ReactTyped
            loop
            typeSpeed={100}
            backSpeed={60}
            backDelay={350}
            strings={[ "Flutter", "Web Apps", "Android Apps", "iOS Apps", "React" ]}
          />
        </Typography>
        
        <div style={{ marginTop: window.innerHeight < minimumHeight ? "8px" : "24px" }} />
        
        <Typography
          variant={window.innerHeight < minimumHeight ? "caption" : "body1"}
          component="h3"
          color="#A9A9A9"
          fontWeight="500"
          sx={{ mx: 2 }}
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay="300"
        >
          BPM Japan LLC のこれまでの制作・開発実績です。<br />
          ここで紹介しているものは、公開を許可して頂いた一部の実績となります。<br />
          これら以外にも多数の制作・開発実績がございます。
        </Typography>
        
        <div style={{ marginTop: window.innerHeight < minimumHeight ? "16px" : "24px" }} />
        
        <Button
          onClick={() => scroller.scrollTo("our-services", {
            duration: scrollSpeed,
            smooth: true
          })}
          variant="outlined"
          color="inherit"
          sx={{
            borderRadius: 24,
            py: window.innerHeight < minimumHeight ? 1 : 2,
            px: window.innerHeight < minimumHeight ? 2 : 4
          }}
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="1200"
          data-aos-offset="0"
        >
          Show our services
        </Button>
      </Grid>
    </Grid>
  </Box>;

export default Home;