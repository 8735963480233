import WebsitesSlideBase from "./WebsitesSlideBase";

const SunbowlSlide = () => <WebsitesSlideBase
  appName="SUN BOWL様 コミック検索システム"
  macbookSrc="/img/development/websites/sunbowl/macbook_top.webp"
  iMacSrc="/img/development/websites/sunbowl/imac_comic_detail.webp"
  serviceLink="http://sunbowl.jacklist.jp/"
  linkLabel="Show SUN BOWL HP"
/>;

export default SunbowlSlide;