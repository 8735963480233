import { Grid, Theme, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { minimumHeight } from "../../constants/app_constants";
import CategoryCard from "./CategoryCard";

const Development = () => {
  const isMobileSize = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  return <Grid
    id="development"
    container
    width="100%"
    minHeight={window.innerHeight}
    alignItems="center"
    justifyContent="center"
    textAlign="center"
    bgcolor="#ffffff"
  >
    <Grid
      item xs={12}
    >
      <Typography
        sx={{ typography: { md: "h2", xs: "h4" } }}
        component="h1"
        data-aos="fade-up"
        data-aos-duration="1200"
      >
        Development
      </Typography>
      
      <Typography
        variant="body1"
        component="h3"
        color="#A9A9A9"
        fontWeight="500"
        mt="8px"
        mx={2}
        data-aos="fade-up"
        data-aos-duration="1200"
      >
        BPM Japanがこれまでに制作・開発に携わらせていただいたサービスです。
      </Typography>
      
      <div style={{ marginTop: window.innerHeight < minimumHeight || isMobileSize ? "24px" : "40px" }} />
      
      <Grid
        container
        columns={9}
        spacing={isMobileSize ? 2 : 4}
        px={isMobileSize ? 2 : 6}
        data-aos="fade-up"
        data-aos-duration="1500"
        data-aos-delay="300"
      >
        <Grid item xs={9} sm={3}>
          <CategoryCard
            iconSrc="img/icons/apps.svg"
            title={
              <>
                Android & iOS<br />
                Mobile Apps
              </>
            }
            shortTitle={
              <>
                Mobile Apps
              </>
            }
            scrollTo="apps"
          />
        </Grid>
        <Grid item xs={9} sm={3}>
          <CategoryCard
            iconSrc="img/icons/web.svg"
            title={
              <>
                Web Sites &<br />
                Web Applications
              </>
            }
            shortTitle={
              <>
                Web Sites
              </>
            }
            scrollTo="web-sites"
          />
        </Grid>
        <Grid item xs={9} sm={3}>
          <CategoryCard
            iconSrc="img/icons/others.svg"
            title={
              <>
                Others /<br />
                Technical Consultation
              </>
            }
            shortTitle={
              <>
                Others
              </>
            }
            scrollTo="others"
          />
        </Grid>
      </Grid>
    </Grid>
  </Grid>;
};

export default Development;
