import { Button, Stack, Typography } from "@mui/material";
import React, { FC } from "react";
import { minimumHeight } from "../../../constants/app_constants";

export type WebsitesSlideBaseProps = {
  appName: string;
  macbookSrc: string;
  iMacSrc: string;
  serviceLink: string;
  linkLabel: string;
};

const WebsitesSlideBase: FC<WebsitesSlideBaseProps> = ({ appName, macbookSrc, iMacSrc, serviceLink, linkLabel }) =>
  <Stack direction="column" alignItems="center">
    <Typography
      variant="h4"
      component="h2">
      {appName}
    </Typography>
    
    <div style={{ marginTop: window.innerHeight < minimumHeight ? "8px" : "16px" }} />
    
    <Stack
      direction="row"
      justifyContent="center"
      spacing={2}
      sx={{ py: 2 }}
    >
      <img
        src={macbookSrc}
        alt="Macbook ScreenShot"
        height={window.innerHeight < minimumHeight ? "200" : "280"}
        width="auto"
      />
      <img
        src={iMacSrc}
        alt="iMac ScreenShot"
        height={window.innerHeight < minimumHeight ? "200" : "280"}
        width="auto"
      />
    </Stack>
    
    <div style={{ marginTop: window.innerHeight < minimumHeight ? "8px" : "16px" }} />
    
    <Button
      onClick={() => window.open(serviceLink)}
      variant="outlined"
      color="inherit"
      sx={{
        borderRadius: 24,
        py: window.innerHeight < minimumHeight ? 1 : 2,
        px: window.innerHeight < minimumHeight ? 2 : 4
      }}
    >
      {linkLabel}
    </Button>
  </Stack>;

export default WebsitesSlideBase;