import { FC } from "react";
import { Avatar, Box, Button, Stack, Typography } from "@mui/material";
import { scroller } from "react-scroll";
import { minimumHeight } from "../../../constants/app_constants";

export type SlideBaseProps = {
  appName: string;
  appIconSrc: string;
  pixel6Src1: string;
  pixel6Src2: string;
  iPhone13Src1: string;
  iPhone13Src2: string;
  scrollTo: string;
};

const SlideBase: FC<SlideBaseProps> = ({
  appName,
  appIconSrc,
  pixel6Src1,
  pixel6Src2,
  iPhone13Src1,
  iPhone13Src2,
  scrollTo
}) =>
  <Stack
    direction="column"
    alignItems="center"
  >
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      spacing={2}
      sx={{
        display: {
          xs: "flex",
          md: "none"
        }
      }}
    >
      <Avatar src={appIconSrc} alt="app icon" />
      <Typography
        variant="h6"
        component="h2">
        {appName}
      </Typography>
    </Stack>
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      spacing={2}
      sx={{
        pt: 2,
        display: {
          xs: "none",
          md: "flex"
        }
      }}
    >
      <Avatar src={appIconSrc} alt="app icon" sx={{ width: 48, height: 48 }} />
      <Typography
        variant="h4"
        component="h2">
        {appName}
      </Typography>
    </Stack>
    
    <div style={{ marginTop: window.innerHeight < minimumHeight ? "8px" : "16px" }} />
    
    <Stack
      direction="row"
      justifyContent="center"
      spacing={2}
      sx={{ py: 2 }}
    >
      <img
        src={pixel6Src1}
        alt="Android ScreenShot 1"
        height={window.innerHeight < minimumHeight ? "200" : "280"}
        width={window.innerHeight < minimumHeight ? "97" : "136"}
      />
      <Box sx={{
        display: {
          xs: "none",
          lg: "block"
        }
      }}>
        <img
          src={pixel6Src2}
          alt="Android ScreenShot 2"
          height={window.innerHeight < minimumHeight ? "200" : "280"}
          width={window.innerHeight < minimumHeight ? "97" : "136"}
        />
      </Box>
      <Box sx={{
        display: {
          xs: "none",
          sm: "block"
        }
      }}>
        <div style={{ width: "16px" }} />
      </Box>
      <Box sx={{
        display: {
          xs: "none",
          lg: "block"
        }
      }}>
        <img
          src={iPhone13Src1}
          alt="iPhone ScreenShot 1"
          height={window.innerHeight < minimumHeight ? "200" : "280"}
          width={window.innerHeight < minimumHeight ? "97" : "136"}
        />
      </Box>
      <img
        src={iPhone13Src2}
        alt="iPhone ScreenShot 2"
        height={window.innerHeight < minimumHeight ? "200" : "280"}
        width={window.innerHeight < minimumHeight ? "97" : "136"}
      />
    </Stack>
    <Button
      onClick={() => scroller.scrollTo(scrollTo, {
        duration: 500,
        smooth: true
      })}
      variant="outlined"
      color="inherit"
      sx={{
        borderRadius: 24,
        py: window.innerHeight < minimumHeight ? 1 : 2,
        px: window.innerHeight < minimumHeight ? 2 : 4
      }}
    >
      {`Show ${appName}`}
    </Button>
  </Stack>;

export default SlideBase;