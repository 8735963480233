import { useNavigate } from "react-router-dom";
import { Button, Container, Stack, Typography } from "@mui/material";
import React from "react";

const MissionTodoPrivacyPolicy = () => {
  const navigate = useNavigate();
  return <Container>
    <Stack
      direction="column"
      spacing={2}
      minHeight={window.innerHeight}
      justifyContent="center"
    >
      <Typography variant="h4" component="h1">
        Mission Todo プライバシーポリシー
      </Typography>
      <Typography variant="body1">
        &nbsp;&nbsp;BPMJapan合同会社（以下「当社」といいます）は、 MissionTodoアプリケーション利用者（以下「利用者」といいます）の個人情報保護の重要性について認識し、
        個人情報の保護に関する法律（以下「個人情報保護法」といいます）を遵守すると共に、以下の プライバシーポリシー（以下「本プライバシーポリシー」といいます）に従い、適切な取扱い及び保護に努めます。<br />
        
        本プライバシーポリシーにおいて、個人情報とは、個人情報保護法の定義に従うものとします。<br />
        当社は、MissionTodoアプリケーション（以下「本アプリ」といいます）の提供にあたって、 広告の配信を行っております。本アプリは、広告配信のためにGoogle 広告、Google
        AdMobを利用します。
        本アプリでは、広告配信の為、以下の広告配信事業者が利用者の情報を自動で取得する場合がございます。 取得する情報、利用目的、第三者への提供等につきましては、
        以下の広告配信事業者のアプリケーション・プライバシーポリシーのリンクよりご確認ください。<br /><br />
        Google広告に関するポリシー<br />
        https://policies.google.com/technologies/ads?hl=ja
      </Typography>
      <div style={{ marginTop: "24px" }} />
      <Button
        variant="text"
        onClick={() => {
          navigate("/");
        }}
      >
        Home
      </Button>
    </Stack>
  </Container>;
};

export default MissionTodoPrivacyPolicy;