import {
  Box,
  Button, Card, CardContent,
  Dialog, DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Stack,
  Typography
} from "@mui/material";
import React from "react";
import Header1Text from "../../common/texts/Header1Text";
import StoreLinkButtons from "../../common/buttons/StoreLinkButtons";
import AppFrameImage from "../../common/images/AppFrameImage";
import { minimumHeight } from "../../../constants/app_constants";

const MysteryBoxContent = () => {
  const [ open, setOpen ] = React.useState(false);
  
  const handleClickOpen = () => {
    setOpen(true);
  };
  
  const handleClose = () => {
    setOpen(false);
  };
  
  const contentId = "mystery-box";
  const appIconSrc = "img/mystery-box/app_icon.webp";
  const appName = "Mystery Box";
  const description = "メッセージに鍵を掛けて共有しよう";
  const mainScreenShot = "img/mystery-box/iPhone13_box_dialog.webp";
  const playStoreLink = "https://play.google.com/store/apps/details?id=jp.co.bpmjapan.mystery_box";
  const appStoreLink = "https://apps.apple.com/jp/app/id1632404989";
  
  return <>
    <Grid
      id={contentId}
      container
      width="100%"
      minHeight={window.innerHeight}
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      bgcolor="#ffffff"
      sx={{
        backgroundImage: "url(\"img/mystery-box/background.webp\")",
        backgroundPosition: "start",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      }}
    >
      <Grid
        item
        sx={{
          display: {
            xs: "none",
            sm: "block"
          }
        }}
        mx={2}
      >
        <Card
          style={{
            borderRadius: "24px",
            backgroundColor: "rgba(255,255,255,0.7)"
          }}
        >
          <CardContent sx={{
            minWidth: "50vw"
          }}>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Stack
                direction="column"
              >
                <div style={{ textAlign: "center" }}>
                  <img src={appIconSrc} alt={`${appName} icon`} height="100px" width="100px" />
                </div>
                
                <Header1Text text={appName} color="#333333" />
                
                <Typography
                  variant="h6"
                  component="h3"
                  color="#333333"
                  fontWeight="500"
                  style={{ marginTop: "8px" }}
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  data-aos-delay="100"
                >
                  {description}
                </Typography>
                
                <StoreLinkButtons
                  playStoreLink={playStoreLink}
                  appStoreLink={appStoreLink}
                />
                
                <Button
                  variant="text"
                  onClick={handleClickOpen}
                  style={{
                    marginTop: "32px"
                  }}
                  color="inherit"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  data-aos-delay="300"
                >
                  プライバシーポリシー
                </Button>
              </Stack>
              
              <Box sx={{
                marginRight: "48px"
              }} />
              
              <AppFrameImage src={mainScreenShot} />
            </Stack>
          </CardContent>
        </Card>
      </Grid>
      
      <Grid
        item
        xs={12}
        sx={{
          display: {
            xs: "block",
            sm: "none"
          }
        }}
        mx={2}
      >
        <Card
          style={{
            borderRadius: "24px",
            backgroundColor: "rgba(255,255,255,0.7)"
          }}
        >
          <CardContent sx={{
            minWidth: "50vw"
          }}>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Header1Text text={appName} color="#333333" />
              
              <Typography
                variant="h6"
                component="h3"
                fontFamily='"M PLUS Rounded 1c"'
                color="#333333"
                sx={{
                  display: {
                    xs: "none",
                    sm: "block"
                  }
                }}
                style={{ marginTop: "8px" }}
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                {description}
              </Typography>
              <Typography
                variant={window.innerHeight < minimumHeight ? "caption" : "subtitle1"}
                component="h3"
                fontFamily='"M PLUS Rounded 1c"'
                color="#333333"
                sx={{
                  display: {
                    xs: "block",
                    sm: "none"
                  }
                }}
                style={{ marginTop: "8px" }}
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                {description}
              </Typography>
              
              <StoreLinkButtons
                playStoreLink={playStoreLink}
                appStoreLink={appStoreLink}
              />
              
              <Button
                variant="text"
                onClick={handleClickOpen}
                style={{
                  marginTop: "16px",
                  marginBottom: "24px"
                }}
                color="inherit"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="300"
              >
                プライバシーポリシー
              </Button>
              
              <Stack direction="row" alignItems="center" spacing={4}>
                <div style={{ textAlign: "center" }}>
                  <img
                    src={appIconSrc}
                    alt={`${appName} icon`}
                    height={window.innerHeight < minimumHeight ? "64px" : "100px"}
                    width={window.innerHeight < minimumHeight ? "64px" : "100px"}
                  />
                </div>
                <AppFrameImage src={mainScreenShot} />
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
    
    <Dialog
      open={open}
      onClose={handleClose}
      aria-describedby={`${appName} Privacy policy`}
      maxWidth="md"
    >
      <DialogTitle>{appName} プライバシーポリシー</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          &nbsp;&nbsp;BPMJapan合同会社（以下「当社」といいます）は、 Mystery Boxアプリケーション利用者（以下「利用者」といいます）の個人情報保護の重要性について認識し、
          個人情報の保護に関する法律（以下「個人情報保護法」といいます）を遵守すると共に、以下の プライバシーポリシー（以下「本プライバシーポリシー」といいます）に従い、適切な取扱い及び保護に努めます。<br />
          
          本プライバシーポリシーにおいて、個人情報とは、個人情報保護法の定義に従うものとします。<br />
          当社は、Mystery Boxアプリケーション（以下「本アプリ」といいます）の提供にあたって、 広告の配信を行っております。本アプリは、広告配信のためにGoogle 広告、Google
          AdMobを利用します。
          本アプリでは、広告配信の為、以下の広告配信事業者が利用者の情報を自動で取得する場合がございます。 取得する情報、利用目的、第三者への提供等につきましては、
          以下の広告配信事業者のアプリケーション・プライバシーポリシーのリンクよりご確認ください。<br /><br />
          Google広告に関するポリシー<br />
          https://policies.google.com/technologies/ads?hl=ja
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleClose}>閉じる</Button>
      </DialogActions>
    </Dialog>
  </>;
};

export default MysteryBoxContent;