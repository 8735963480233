import {
  Box, Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Stack,
  Theme,
  Typography,
  useMediaQuery
} from "@mui/material";
import Carousel from "react-material-ui-carousel";
import { minimumHeight } from "../../../constants/app_constants";

const Others = () => {
  const isMobileSize = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  return <Grid
    id="others"
    container
    width="100%"
    minHeight={window.innerHeight}
    alignItems="center"
    justifyContent="center"
    textAlign="center"
    bgcolor="#f5f8fc"
  >
    <Grid
      item xs={12}
    >
      <Typography
        sx={{ typography: { md: "h2", xs: "h4" } }}
        component="h1"
        data-aos="fade-up"
        data-aos-duration="1200"
      >
        Others
      </Typography>
      
      <Typography
        variant={window.innerHeight < minimumHeight ? "caption" : "body1"}
        component="h3"
        color="#A9A9A9"
        fontWeight="500"
        mt="8px"
        mx={2}
        data-aos="fade-up"
        data-aos-duration="1200"
      >
        Apps, Web Sites以外の制作・開発や、技術相談対応、講習等の実績です。<br />
        公開許可を頂いた実績のみ掲載しています。<br />
        {isMobileSize ? null : "Flutterにおける技術相談対応の実績が多くなっています。"}
      </Typography>
      
      <div style={{ marginTop: window.innerHeight < minimumHeight ? "16px" : "32px" }} />
      
      <Container
        data-aos="fade-up"
        data-aos-duration="1500"
        data-aos-delay="300"
      >
        <Carousel
          navButtonsAlwaysVisible
          animation="slide"
          duration={500}
          autoPlay={false}
          cycleNavigation={false}
        >
          <Stack direction="column" spacing={2} alignItems="center">
            <Card sx={{
              maxWidth: 520
            }}>
              <CardMedia
                component="img"
                height={200}
                image="/img/development/others/coconala_platinum.png"
                alt="Coconala"
                style={{
                  objectFit: "contain"
                }}
              />
            </Card>
            <Typography variant="body1">
              ココナラにて、Flutterに関する技術相談や、アプリ開発、エラー解決等のご依頼を頂いております。<br />
              ココナラの会員ランク最上位のプラチナランクを頂いております。
            </Typography>
            <Button
              variant="text"
              onClick={() => {
                window.open("https://coconala.com/users/711512");
              }}
            >
              ココナラからのご依頼はこちら
            </Button>
          </Stack>
          
          <Stack direction="column" spacing={2} alignItems="center">
            <Card sx={{
              maxWidth: 520
            }}>
              <CardMedia
                component="img"
                height={200}
                image="/img/development/others/flutter_lecture.png"
                alt="Flutter Lecture"
                style={{
                  objectFit: "contain"
                }}
              />
            </Card>
            <Typography variant="body1">
              開発者の方を対象に、ソフトウェア開発手法や、Flutterについて等の講座を行っています。
            </Typography>
          </Stack>
        </Carousel>
      </Container>
    </Grid>
  </Grid>;
};

export default Others;