import SlideBase from "./SlideBase";

const MysteryBoxSlide = () =>
  <SlideBase
    appName="Mystery Box"
    appIconSrc="img/mystery-box/app_icon.webp"
    
    pixel6Src1="img/mystery-box/pixel6_signin.webp"
    pixel6Src2="img/mystery-box/pixel6_box_dialog.webp"
    
    iPhone13Src1="img/mystery-box/iPhone13_signin.webp"
    iPhone13Src2="img/mystery-box/iPhone13_box_dialog.webp"
    scrollTo="mystery-box"
  />;

export default MysteryBoxSlide;