import ContentBase from "../ContentBase";

const SirenCollectionContent = () => <ContentBase
  appName="サイレンコレクション"
  appNameColor="#ffffff"
  contentId="siren-collection"
  bgColor="#f7b1c6"
  appIconSrc="img/siren-collection/appicon.png"
  description="いろんなサイレンのおとをきいてみよう！"
  descriptionColor="#ffffff"
  mainScreenShotSrc="img/siren-collection/siren_iphone13_kyukyu.webp"
  playStoreLink="https://play.google.com/store/apps/details?id=jp.co.bpmjapan.siren_collection"
  appStoreLink="https://apps.apple.com/jp/app/id1645780316"
  privacyPolicyColor="#FFFFFF"
  privacyPolicy={
    <>
      &nbsp;&nbsp;BPMJapan合同会社（以下「当社」といいます）は、 サイレンコレクションアプリケーション利用者（以下「利用者」といいます）の個人情報保護の重要性について認識し、
      個人情報の保護に関する法律（以下「個人情報保護法」といいます）を遵守すると共に、以下の プライバシーポリシー（以下「本プライバシーポリシー」といいます）に従い、適切な取扱い及び保護に努めます。<br />
      
      本プライバシーポリシーにおいて、個人情報とは、個人情報保護法の定義に従うものとします。<br />
      当社は、サイレンコレクションアプリケーション（以下「本アプリ」といいます）の提供にあたって、 広告の配信を行っております。本アプリは、広告配信のためにGoogle 広告、Google
      AdMobを利用します。
      本アプリでは、広告配信の為、以下の広告配信事業者が利用者の情報を自動で取得する場合がございます。 取得する情報、利用目的、第三者への提供等につきましては、
      以下の広告配信事業者のアプリケーション・プライバシーポリシーのリンクよりご確認ください。<br /><br />
      Google広告に関するポリシー<br />
      https://policies.google.com/technologies/ads?hl=ja
    </>
  }
/>;

export default SirenCollectionContent;