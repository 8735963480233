import { Container, Grid, Typography } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import MysteryBoxSlide from "./slides/MysteryBoxSlide";
import MissionTodoSlide from "./slides/MissionTodoSlide";
import { minimumHeight } from "../../constants/app_constants";
import HundredMasterSlide from "./slides/HundredMasterSlide";

const OurServices = () => <Grid
  id="our-services"
  container
  width="100%"
  minHeight={window.innerHeight}
  alignItems="center"
  justifyContent="center"
  textAlign="center"
  bgcolor="#ffffff">
  <Grid
    item xs={12}
  >
    <Typography
      sx={{ typography: { md: "h2", xs: "h4" } }}
      component="h1"
      data-aos="fade-up"
      data-aos-duration="1200"
    >
      Our Services
    </Typography>
    
    <Typography
      variant={window.innerHeight < minimumHeight ? "caption" : "body1"}
      component="h3"
      color="#A9A9A9"
      fontWeight="500"
      mt="8px"
      mx={2}
      data-aos="fade-up"
      data-aos-duration="1200"
    >
      企画から制作・開発・運用まで全てBPM Japanで行っているサービスです。
    </Typography>
    
    <div style={{ marginTop: window.innerHeight < minimumHeight ? "16px" : "32px" }} />
    
    <Container
      data-aos="fade-up"
      data-aos-duration="1500"
      data-aos-delay="300"
    >
      <Carousel
        navButtonsAlwaysVisible
        animation="slide"
        duration={500}
        autoPlay={false}
        cycleNavigation={false}
      >
        <MissionTodoSlide />
        <MysteryBoxSlide />
        <HundredMasterSlide />
      </Carousel>
    </Container>
  </Grid>
</Grid>;

export default OurServices;